import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import api from '../../app/api/api'
import handleError from '../../app/api/handleError'
import { RootState, AppThunk } from '../../app/store'

type Color = string | 'currentColor'

export type IconColors = {
  [color: string]: Color
}

export enum ProductTypeEnum {
  ICONS = 'icons',
  ILLUSTRATIONS = 'illustrations',
  EMOJIS = 'emojis',
  ELEMENTS = 'elements',
}

export enum StyleFilterEnum {
  LINE = 'line',
  SOLID = 'solid',
  FLAT = 'flat',
  DUO = 'duo',
  HANDRAWN = 'handrawn',
  CREATIVE = 'creative',
  GRADIENT = 'gradient',
  REMIX = 'remix',
  NEON = 'neon',
  POP = 'pop',
}

export interface ColorsScan {
  familyColorScheme: IconColors
  brokenIcons: IconInAssetsListDto[]
  total: number
}

export interface RepaintIconResult {
  icon: IconInAssetsListDto
  beforeSvg: string
  afterSvg: string
}

export interface RepaintResults {
  totalSuccess: number
  totalNotModified: number
  totalFailed: number
  success: RepaintIconResult[]
  notModified: IconInAssetsListDto[]
  failed: IconInAssetsListDto[]
}

export interface Product {
  hash: string
  stripeProductId: string
  name: string
  slug?: string
  cover?: string
  totalIcons?: number
  totalIllustrations?: number
  description: string
  group: string
  visible: boolean
  stripeMonthlyPriceId: string
  stripeYearlyPriceId: string
  stripeOneTimePriceId: string
}

export interface FamilyGroup {
  hash: string
  name: string
  slug: string
  description: string
  subtitle: string
  siteUrl: string
  color: string
  order: number
  families?: Family[]
}
export interface Family {
  id: number
  hash: string
  name: string
  slug: string
  order: number
  defaultSize: number
  isFree: boolean
  defaultLazyLoadItems: number
  cover: string
  extra: { is_beta: boolean }
  iconCount: number
  createdAt: Date
  updatedAt: Date
  visible: boolean
  description: string
  isNew: boolean
  legacyHavePremium: boolean
  onlyLegacyVisible: boolean
  oneColored: boolean
  defaultSidebarSize: number
  defaultDownloadSize: number
  productType: ProductTypeEnum
  maxFreeSizeDownload: number
  hasGlobalColors: boolean
  metaTitle: string
  metaDescription: string
  strokeAllowed: boolean
  defaultStrokeWidth: boolean
  visibleOnlyOnFreebiesTab: boolean
  paymentLink: string
  sendOwlFileId: string
  familyGroupHash?: string
  style?: StyleFilterEnum
  displayInFreeAndPremium?: boolean
  authorName?: string
  authorLink?: string
  licenseName?: string
  licenseLink?: string
  isThirdParty: boolean
  relatedFamilyHash?: string
}

export interface Category {
  id: number
  hash: string
  name: string
  path: string
  family: Family
  slug: string
  isFree: boolean
  order: number
  isNew: boolean
  visible: boolean
}

export interface Subcategory {
  id: number
  hash: string
  name: string
  slug: string
  path: string
  category: Category
  visible: boolean
  order: number
}

export type Icon = {
  id: number
  hash: string
  name: string
  description: string | null
  path: string
  slug: string
  imagePublicId: string
  svg: string
  originalSvg: string
  tags: string[]
  /**
   * @deprecated Now tags will be stored in only one place, the tags field. That's because now we need to store the tags orderred.
   * by relevance.
   */
  autoTags: string[]
  /**
   * @deprecated Now tags will be stored in only one place, the tags field. That's because now we need to store the tags orderred.
   * by relevance.
   */
  autoTagsSynonyms: string[]
  visible: boolean
  createdAt: Date
  updatedAt: Date
}

export type IconInAssetsListDto = {
  name: string

  description: string | null

  hash: string

  slug: string

  svg: string

  imagePublicId?: string | null

  hasPremiumAccess?: boolean

  visible: boolean

  family: Family

  category: Category

  subcategory: Subcategory
}

export interface IconWithDetails extends Icon {
  family: Family
  category: Category
  subcategory: Subcategory
}

export type DrawerType =
  | 'family'
  | 'category'
  | 'subcategory'
  | 'icon'
  | 'family-upload'
  | 'multiple-icons'
  | 'color-scan'
  | 'fix-restore'
  | 'fix-family'
  | 'fix-all-families'
  | 'restore-family'
  | 'family-group'
  | 'upload-category-assets'
  | 'fix-tags'

interface AssetsDrawer {
  visible: boolean
  type: DrawerType
  data?: Family | Subcategory | Category | Icon | FamilyGroup | null
}

interface AssetsState {
  families: Family[]
  family: Family | null
  category: Category | null
  categories: Category[]
  subcategory: Subcategory | null
  subcategories: any[]
  icons: Icon[]
  categoryIcons: Icon[]
  familyIcons: Icon[]
  totalCategoryIcons: number
  totalFamilyIcons: number
  icon: Icon | null
  selectedIcons: Icon[]
  showTags: boolean
  previewSize: number
  selectAllCheckbox: boolean
  loadingFamilies: boolean
  loadingCategories: boolean
  loadingSubcategories: boolean
  loadingIcons: boolean
  loadingCategoriesOrder: boolean
  errorMessage: string
  drawer: AssetsDrawer
  updatingModelDataset: boolean
  autoTaggingAssets: boolean
  isUpdatingAssetsOnSendOwl: boolean
}

const initialState: AssetsState = {
  family: null,
  families: [],
  category: null,
  categories: [],
  subcategory: null,
  subcategories: [],
  icons: [],
  categoryIcons: [],
  familyIcons: [],
  totalCategoryIcons: 0,
  totalFamilyIcons: 0,
  icon: null,
  selectedIcons: [],
  showTags: true,
  previewSize: 100,
  selectAllCheckbox: false,
  loadingFamilies: true,
  loadingCategories: false,
  loadingSubcategories: false,
  loadingIcons: false,
  loadingCategoriesOrder: false,
  errorMessage: '',
  drawer: { visible: false, type: 'icon' },
  updatingModelDataset: false,
  autoTaggingAssets: false,
  isUpdatingAssetsOnSendOwl: false,
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    selectedFamily: (state, action: PayloadAction<string>) => {
      state.family = state.families.find((f) => f.hash === action.payload) || null
    },
    selectedCategory: (state, action: PayloadAction<string>) => {
      state.category = state.categories.find((f) => f.hash === action.payload) || null
    },
    selectedSubcategory: (state, action: PayloadAction<string>) => {
      state.subcategory = state.subcategories.find((f) => f.hash === action.payload) || null
      state.selectedIcons = []
      state.selectAllCheckbox = false
    },
    selectedIcon: (state, action: PayloadAction<Icon | null>) => {
      state.icon = action.payload
    },
    toggleSelectedIcons: (state, action: PayloadAction<Icon>) => {
      state.selectedIcons = handleToggleSelectedIcon(state.selectedIcons, action.payload)
    },
    toggleSelectedAllIcons: (state, action: PayloadAction<boolean>) => {
      state.selectAllCheckbox = action.payload
      if (action.payload) {
        state.selectedIcons = state.icons
      } else {
        state.selectedIcons = []
      }
    },
    fetchFamiliesRequest: (state) => {
      state.families = []
      state.categories = []
      state.subcategories = []
      state.icons = []
      state.family = null
      state.category = null
      state.subcategory = null
      state.loadingFamilies = true
      state.errorMessage = ''
    },
    fetchFamiliesSuccess: (state, action: PayloadAction<Family[]>) => {
      state.families = action.payload
      state.loadingFamilies = false
      state.errorMessage = ''
    },
    fetchFamiliesFailed: (state, action: PayloadAction<string>) => {
      state.loadingFamilies = false
      state.errorMessage = action.payload
    },
    fetchCategoriesRequest: (state) => {
      state.category = null
      state.subcategory = null
      state.categories = []
      state.subcategories = []
      state.icons = []
      state.loadingCategories = true
    },
    fetchCategoriesSuccess: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload
      state.subcategories = []
      state.icons = []
      state.loadingCategories = false
    },
    fetchCategoriesFailed: (state, action: PayloadAction<string>) => {
      state.loadingCategories = false
      state.errorMessage = action.payload
    },
    fetchSubcategoriesRequest: (state) => {
      state.subcategory = null
      state.subcategories = []
      state.icons = []
      state.loadingSubcategories = true
    },
    fetchSubcategoriesSuccess: (state, action: PayloadAction<Subcategory[]>) => {
      state.subcategories = action.payload
      state.icons = []
      state.loadingSubcategories = false
    },
    fetchSubcategoriesFailed: (state, action: PayloadAction<string>) => {
      state.loadingSubcategories = false
      state.errorMessage = action.payload
    },
    fetchIconsRequest: (state) => {
      state.icons = []
      state.loadingIcons = true
    },
    fetchIconsSuccess: (state, action: PayloadAction<Icon[]>) => {
      state.icons = action.payload
      state.loadingIcons = false
    },
    fetchIconsByFamilyRequest: (state) => {
      state.familyIcons = []
      state.loadingIcons = true
    },
    fetchIconsByFamilySuccess: (state, action: PayloadAction<{ total: number; icons: Icon[] }>) => {
      state.familyIcons = action.payload.icons
      state.totalFamilyIcons = action.payload.total
      state.loadingIcons = false
    },
    fetchIconsByCategoryRequest: (state) => {
      state.categoryIcons = []
      state.loadingIcons = true
    },
    fetchIconsByCategorySuccess: (
      state,
      action: PayloadAction<{ total: number; icons: Icon[] }>
    ) => {
      state.categoryIcons = action.payload.icons
      state.totalCategoryIcons = action.payload.total
      state.loadingIcons = false
    },
    updateFamilyIconsOnList: (state, action: PayloadAction<Icon>) => {
      const iconsList = state.familyIcons
      const foundIndex = state.familyIcons.findIndex((x) => x.hash === action.payload.hash)
      iconsList[foundIndex] = action.payload
      state.familyIcons = iconsList
    },
    updateCategoryIconsOnList: (state, action: PayloadAction<Icon>) => {
      const iconsList = state.categoryIcons
      const foundIndex = state.categoryIcons.findIndex((x) => x.hash === action.payload.hash)
      iconsList[foundIndex] = action.payload
      state.categoryIcons = iconsList
    },
    postAutoTaggingRequest: (state) => {
      state.autoTaggingAssets = true
    },
    postAutoTaggingSuccess: (state) => {
      state.autoTaggingAssets = false
    },
    postAutoTaggingFailed: (state, action: PayloadAction<string>) => {
      state.autoTaggingAssets = false
      state.errorMessage = action.payload
    },
    putUpdateAssetsOnSendOwl: (state) => {
      state.isUpdatingAssetsOnSendOwl = true
    },
    putUpdateAssetsOnSendOwlSuccess: (state) => {
      state.isUpdatingAssetsOnSendOwl = false
    },
    putUpdateAssetsOnSendOwlFailed: (state, action: PayloadAction<string>) => {
      state.isUpdatingAssetsOnSendOwl = false
      state.errorMessage = action.payload
    },
    fetchIconsFailed: (state, action: PayloadAction<string>) => {
      state.icons = []
      state.loadingIcons = false
      state.errorMessage = action.payload
    },
    setShowTags: (state, action: PayloadAction<boolean>) => {
      state.showTags = action.payload
    },
    setPreviewSize: (state, action: PayloadAction<number>) => {
      state.previewSize = action.payload
    },
    toggleDrawer: (state, action: PayloadAction<AssetsDrawer>) => {
      state.drawer = action.payload
    },
    updateIconOnList: (state, action: PayloadAction<Icon>) => {
      const iconsList = state.icons
      const foundIndex = state.icons.findIndex((x) => x.hash === action.payload.hash)
      iconsList[foundIndex] = action.payload
      state.icons = iconsList
    },
    addIconOnList: (state, action: PayloadAction<Icon>) => {
      const iconsList = state.icons
      iconsList.unshift(action.payload)
      state.icons = iconsList
    },
    removeIconsOnList: (state, action: PayloadAction<{ hash: string; name: string }[]>) => {
      const iconsList = state.icons.filter(
        (el) => !action.payload.map((i) => i.hash).includes(el.hash)
      )
      state.icons = iconsList
      const toggledIconsList = state.selectedIcons.filter(
        (el) => !action.payload.map((i) => i.hash).includes(el.hash)
      )
      state.selectedIcons = toggledIconsList
    },
    updateCategoryOnList: (state, action: PayloadAction<Category>) => {
      const categoriesList = state.categories
      const foundIndex = state.categories.findIndex((x) => x.hash === action.payload.hash)
      categoriesList[foundIndex] = action.payload
      state.categories = categoriesList
    },
    updateCategoriesOrderRequest: (state) => {
      state.loadingCategoriesOrder = true
    },
    updateCategoriesSuccess: (state, action: PayloadAction<Category[]>) => {
      state.loadingCategoriesOrder = false
      state.categories = action.payload
    },
    updateCategoriesOrderFailed: (state, action: PayloadAction<string>) => {
      state.loadingCategoriesOrder = false
      state.errorMessage = action.payload
    },
    updateSubcategories: (state, action: PayloadAction<Subcategory[]>) => {
      state.subcategories = action.payload
    },
    addCategoryOnList: (state, action: PayloadAction<Category>) => {
      const categoriesList = state.categories
      categoriesList.unshift(action.payload)
      state.categories = categoriesList
    },
    removeCategoryOnList: (state, action: PayloadAction<string>) => {
      const categoriesList = state.categories
      const foundIndex = categoriesList.findIndex((x) => x.hash === action.payload)
      categoriesList.splice(foundIndex, 1)
      state.categories = categoriesList
      state.icons = []
      state.subcategories = []
      if (state.category?.hash === action.payload) {
        state.category = null
        state.subcategory = null
      }
    },
    updateSubcategoryOnList: (state, action: PayloadAction<Subcategory>) => {
      const subcategoriesList = state.subcategories
      const foundIndex = state.subcategories.findIndex((x) => x.hash === action.payload.hash)
      subcategoriesList[foundIndex] = action.payload
      state.subcategories = subcategoriesList
    },
    addSubcategoryOnList: (state, action: PayloadAction<Subcategory>) => {
      const subcategoriesList = state.subcategories
      subcategoriesList.unshift(action.payload)
      state.subcategories = subcategoriesList
    },
    removeSubcategoryOnList: (state, action: PayloadAction<string>) => {
      const subcategoriesList = state.subcategories
      const foundIndex = subcategoriesList.findIndex((x) => x.hash === action.payload)
      subcategoriesList.splice(foundIndex, 1)
      state.subcategories = subcategoriesList
      state.icons = []
      if (state.subcategory?.hash === action.payload) {
        state.subcategory = null
      }
    },
    updateFamilyOnList: (state, action: PayloadAction<Family>) => {
      const familiesList = state.families
      const foundIndex = familiesList.findIndex((x) => x.hash === action.payload.hash)
      familiesList[foundIndex] = action.payload
      state.families = familiesList
    },
    addFamilyOnList: (state, action: PayloadAction<Family>) => {
      const familiesList = state.families
      familiesList.unshift(action.payload)
      state.families = familiesList
      state.categories = []
      state.subcategories = []
      state.icons = []
      state.category = null
      state.subcategory = null
      state.icon = null
    },
    removeFamilyOnList: (state, action: PayloadAction<string>) => {
      const familiesList = state.families
      const foundIndex = familiesList.findIndex((x) => x.hash === action.payload)
      familiesList.splice(foundIndex, 1)
      state.families = familiesList
      state.icons = []
      state.categories = []
      state.subcategories = []
      if (state.family?.hash === action.payload) {
        state.family = null
        state.category = null
        state.subcategory = null
      }
    },
  },
})

export const { actions } = assetsSlice

export const getFamilies = (): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.fetchFamiliesRequest())
    const response = await api.get('/v4/families')
    dispatch(actions.fetchFamiliesSuccess(response.data))
  } catch (error: any) {
    dispatch(actions.fetchFamiliesFailed(error.message))
  }
}

export const updateCategoriesOrder =
  (categories: Category[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.updateCategoriesOrderRequest())
      const response = await api.patch('/v4/categories/order', { categories })
      message.success('Categories updated!')
      dispatch(actions.updateCategoriesSuccess(response.data))
    } catch (error: any) {
      dispatch(actions.updateCategoriesOrderFailed(error.message))
    }
  }

export const updateSubcategoriesOrder =
  (subcategories: Subcategory[]): AppThunk =>
  async (dispatch) => {
    dispatch(actions.updateSubcategories(subcategories))
  }

export const getCategories =
  (familyHash: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.selectedFamily(familyHash))
      dispatch(actions.fetchCategoriesRequest())
      const response = await api.get(`/v4/families/${familyHash}/all-categories`)
      dispatch(actions.fetchCategoriesSuccess(response.data))
    } catch (error: any) {
      dispatch(actions.fetchCategoriesFailed(error.message))
    }
  }

export const getSubcategories =
  (categoryHash: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.selectedCategory(categoryHash))
      dispatch(actions.fetchSubcategoriesRequest())
      const response = await api.get(`/v4/categories/${categoryHash}/subcategories`)
      dispatch(actions.fetchSubcategoriesSuccess(response.data))
    } catch (error: any) {
      dispatch(actions.fetchSubcategoriesFailed(error.message))
    }
  }

export const getIcons =
  (subcategoryHash: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.selectedSubcategory(subcategoryHash))
      dispatch(actions.fetchIconsRequest())
      const response = await api.get(`/v4/subcategories/${subcategoryHash}/icons`)
      dispatch(actions.fetchIconsSuccess(response.data))
    } catch (error: any) {
      dispatch(actions.fetchIconsFailed(error.message))
    }
  }

export const generateTagsForFamily =
  (familyHash: string, productType: ProductTypeEnum): AppThunk =>
  async (dispatch, getState) => {
    const { assets } = getState()

    try {
      dispatch(actions.postAutoTaggingRequest())
      const { data } = await api.post(`/v4/ai/auto-tagging/family/${familyHash}`, {
        productType,
      })

      if (assets.subcategory) {
        dispatch(getIcons(assets.subcategory.hash))
      }

      dispatch(actions.postAutoTaggingSuccess())
      message.info({
        key: 'taggingMessage',
        content: data.message,
        duration: 0,
        onClick: () => message.destroy('taggingMessage'),
      })
    } catch (error: any) {
      message.error(handleError(error))
      dispatch(actions.postAutoTaggingFailed(handleError(error)))
    }
  }

export const generateTagsForCategory =
  (categoryHash: string, productType: ProductTypeEnum): AppThunk =>
  async (dispatch, getState) => {
    const { assets } = getState()

    try {
      dispatch(actions.postAutoTaggingRequest())
      await api.post(`/v4/ai/auto-tagging/category/${categoryHash}`, {
        productType,
      })

      if (assets.subcategory) {
        dispatch(getIcons(assets.subcategory.hash))
      }

      dispatch(actions.postAutoTaggingSuccess())
      message.info({
        key: 'taggingMessage',
        content: 'Auto tagging category finished.',
        duration: 0,
        onClick: () => message.destroy('taggingMessage'),
      })
    } catch (error: any) {
      message.error(handleError(error))
      dispatch(actions.postAutoTaggingFailed(handleError(error)))
    }
  }

export const generateTagsForIcons =
  (iconsHashes: string[], productType: ProductTypeEnum): AppThunk =>
  async (dispatch) => {
    try {
      await api.post('/v4/ai/auto-tagging/icons', {
        productType,
        iconsHashes,
      })
    } catch (error: any) {
      message.error(handleError(error))
      dispatch(actions.postAutoTaggingFailed(handleError(error)))
    }
  }

export const generateDescriptionsForFamily =
  (familyHash: string, overrideExistingDescriptions: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { data } = await api.patch(`/v4/ai/descriptions/family/${familyHash}`, {
        overrideExistingDescriptions,
      })

      message.info({
        key: 'descriptionMessage',
        content: data.message,
        onClick: () => message.destroy('descriptionMessage'),
      })
    } catch (error: any) {
      message.error(handleError(error))
      dispatch(actions.postAutoTaggingFailed(handleError(error)))
    }
  }

export const updateAssetsOnSendOwl =
  (familyHash: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.putUpdateAssetsOnSendOwl())
      await api.put(`/v4/families/${familyHash}/send-owl`)

      dispatch(actions.putUpdateAssetsOnSendOwlSuccess())
      message.info({
        key: 'updateFinished',
        content: 'Assets successfully updated on SendOwl.',
        duration: 0,
        onClick: () => message.destroy('updateFinished'),
      })
    } catch (error: any) {
      message.error(handleError(error))
      dispatch(actions.putUpdateAssetsOnSendOwlFailed(handleError(error)))
    }
  }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.assets.value)`
export const selectFamily = (state: RootState) => state.assets.family
export const selectLoadingFamilies = (state: RootState) => state.assets.loadingFamilies
const selectFamilies = (state: RootState) => state.assets.families
export const selectFamiliesByOrder = createSelector([selectFamilies], (families) => {
  const byOrder = [...families]
  byOrder.sort((a, b) => (a.order && b.order ? a.order - b.order : -1))
  return byOrder
})

export const selectCategory = (state: RootState) => state.assets.category
export const selectLoadingCategories = (state: RootState) => state.assets.loadingCategories
const selectCategories = (state: RootState) => state.assets.categories
export const selectCategoriesByOrder = createSelector([selectCategories], (categories) => {
  const byOrder = [...categories]
  byOrder.sort((a, b) => (a.order && b.order ? a.order - b.order : -1))
  return byOrder
})

export const selectSubcategory = (state: RootState) => state.assets.subcategory
export const selectLoadingSubcategories = (state: RootState) => state.assets.loadingSubcategories
const selectSubcategories = (state: RootState) => state.assets.subcategories
export const selectSubcategoriesByOrder = createSelector([selectSubcategories], (subcategories) => {
  const byOrder = [...subcategories]
  byOrder.sort((a, b) => (a.order && b.order ? a.order - b.order : -1))
  return byOrder
})

export const selectLoadingIcons = (state: RootState) => state.assets.loadingIcons
export const selectIcons = (state: RootState) => state.assets.icons
export const selectIcon = (state: RootState) => state.assets.icon
export const selectSelectedIcons = (state: RootState) => state.assets.selectedIcons
export const selectIconsByFamily = (state: RootState) => state.assets.familyIcons
export const selectTotalIconsByFamily = (state: RootState) => state.assets.totalFamilyIcons
export const selectIconsByCategory = (state: RootState) => state.assets.categoryIcons
export const selectTotalIconsByCategory = (state: RootState) => state.assets.totalCategoryIcons
export const selectUpdatingModelDataset = (state: RootState) => state.assets.updatingModelDataset
export const selectAutoTaggingAssets = (state: RootState) => state.assets.autoTaggingAssets
export const selectIsUpdatingAssetsOnSendOwl = (state: RootState) =>
  state.assets.isUpdatingAssetsOnSendOwl

export const selectShowTags = (state: RootState) => state.assets.showTags
export const selectPreviewSize = (state: RootState) => state.assets.previewSize
export const selectAllCheckbox = (state: RootState) => state.assets.selectAllCheckbox

export const selectDrawer = (state: RootState) => state.assets.drawer

export default assetsSlice.reducer

const handleToggleSelectedIcon = (selectedIcons: Icon[], icon: Icon): Icon[] => {
  const foundIndex = selectedIcons.findIndex((i) => i.hash === icon.hash)
  if (foundIndex >= 0) {
    const iconsList = selectedIcons
    iconsList.splice(foundIndex, 1)
    return iconsList
  }
  return [...selectedIcons, icon]
}
